import React from 'react'

import { CurrencyCode, Order, OrderLine } from '~common/generated/admin-graphql'
import { Price } from '~common/components/product'
import { RemoveCouponForm } from '~common/components/coupon/RemoveCouponForm'

type Interval = 'month' | 'year' | 'week' | 'future'
type RecurringTotals = {
  [key in Interval]: { [key: number]: number }
}

export const OrderTotals: React.FC<{
  order: Order
  pricesIncludeTax?: boolean
}> = ({ order, pricesIncludeTax }) => {
  const currencyCode = order?.currencyCode || CurrencyCode.Usd
  /**
   * Recurring groupings:
   * Structure
   *  {
   *     month: {
   *        `${x}`: total,
   *        `${x}`: total,
   *     }
   *     year: {
   *        `${x}`: total,
   *        `${x}`: total,
   *     }
   *     week: {
   *        `${x}`: total,
   *        `${x}`: total,
   *     }
   *  }
   * */

  const recurringTotals = React.useMemo(
    () =>
      order.lines.reduce(
        (acc, line: OrderLine) => {
          const subscriptions =
            line.stripeSubscriptions ||
            line.acceptBlueSubscriptions ||
            line.subscriptions
          const { productVariant } = line
          const { autoRenew } = line.productVariant?.customFields ?? {}
          if (subscriptions) {
            // recurring amounts can be multiple with difference in interval.
            for (const sub of subscriptions) {
              if (!sub.recurring) {
                return acc
              }
              const { recurring } = sub
              let intervalStr = recurring.interval.toString() as Interval
              let intervalCount = recurring.intervalCount
              if (!autoRenew) {
                intervalStr = 'future'
                intervalCount = 1
              }
              if (!acc[intervalStr]) {
                acc[intervalStr] = {}
              }
              if (typeof acc[intervalStr][intervalCount] === 'undefined') {
                acc[intervalStr][intervalCount] = 0
              }
              const currentVal = acc[intervalStr][intervalCount]

              acc[intervalStr][intervalCount] =
                currentVal + sub.recurring?.amount || 0
            }
          }
          return acc
        },
        {
          future: {},
          month: {},
          year: {},
          week: {},
        } as RecurringTotals,
      ),
    [order],
  )

  const recurringCount = Object.keys(recurringTotals).filter(
    (interval) => Object.keys(recurringTotals[interval as Interval]).length,
  ).length
  return (
    <>
      {order.couponCodes.length ? (
        <div className="mb-2">
          {order.couponCodes.map((couponCode, idx) => (
            <div
              key={couponCode}
              className="flex items-center justify-between w-full text-sm"
            >
              <RemoveCouponForm
                couponCode={couponCode}
                currentCouponCodes={order.couponCodes}
              />
              <p>
                <Price
                  priceWithTax={order.discounts[idx]?.amount ?? 0}
                  currencyCode={currencyCode}
                />
              </p>
            </div>
          ))}
        </div>
      ) : null}
      {recurringCount === 0 ? (
        <div className="flex justify-between text-sm font-normal text-gray-700 dark:text-slate-400">
          <p className="font-medium">Recurring</p>
          <p>
            <Price
              priceWithTax={0}
              interval="month"
              intervalCount={1}
              currencyCode={currencyCode}
            />
          </p>
        </div>
      ) : null}
      {recurringCount > 0
        ? Object.keys(recurringTotals).map((interval, idx) =>
            Object.keys(recurringTotals[interval as Interval])
              .filter((interval) => interval !== 'future')
              .map((intervalC, idx2) => {
                const intervalCount = parseInt(intervalC, 10)
                const total =
                  recurringTotals[interval as Interval][intervalCount]
                return (
                  <div
                    key={`price-${idx}-${idx2}`}
                    className="flex justify-between text-sm font-normal text-gray-700 dark:text-slate-300"
                  >
                    <p className="font-medium dark:text-slate-400">
                      {idx || idx2
                        ? ''
                        : interval === 'future'
                          ? 'Upcoming'
                          : 'Recurring'}
                    </p>
                    <p>
                      <Price
                        priceWithTax={total}
                        interval={interval}
                        intervalCount={intervalCount}
                        currencyCode={currencyCode}
                      />
                    </p>
                  </div>
                )
              }),
          )
        : null}
      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-slate-300 my-1">
        <p className="font-medium dark:text-slate-400">Pay now</p>
        <p>
          {currencyCode && (
            <Price
              priceWithTax={
                (pricesIncludeTax ? order?.subTotalWithTax : order?.subTotal) ??
                0
              }
              currencyCode={currencyCode}
            />
          )}
        </p>
      </div>
      {recurringCount > 0 ? (
        <p className="mt-0.5 text-xs text-gray-500 dark:text-slate-400">
          Periodic memberships can require a downpayment and, if you are signing
          up mid cycle, may be pro-rated through the end of the current cycle.{' '}
          <br /> Memberships automatically renew.
        </p>
      ) : null}
      {/* <p className="mt-0.5 text-sm text-gray-500">
                        Shipping will be calculated at
                        checkout.
                      </p> */}
    </>
  )
}
